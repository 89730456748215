import {StarRating} from './ui/molecules';
import {useMemo} from 'react';
import {useGate, useUnit} from 'effector-react';
import {
  $filteredStores,
  $orderDetails,
  $page,
  $reviews,
  $selectedBranchesIds,
  $selectedRatingRange,
  $selectedStoreBranches,
  $selectedStoreId,
  branchesIdsChanged,
  orderDetailsClosed,
  orderDetailsOpened,
  pageChanged,
  ratingChanged,
  reviewsSearched,
  storeIdChanged,
  $isOrderDetailsOpen,
  $selectedStore,
  fetchReviewsFx,
  fetchOrderFx,
  imagesSelected,
  $selectedImages,
  imagesClosed,
  $isImagesOpen,
  $isSearchButtonDisabled,
  ratingGate,
} from '../model';
import styles from './page.module.scss';
import {Button, Drawer, Icon, Pagination, Select, Space, Typography, Skeleton} from '@express-24/theseus-ui';
import {RatingFilters} from '../model';
import {OrderDetails} from '@widgets/order-details';
import {ImageCover} from '@shared/ui/molecules/image-cover';
import {EmptyState} from '@widgets/empty-state';
import 'yet-another-react-lightbox/dist/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

const PAGE_SIZE = 10;

export const Rating = () => {
  const {
    handleSearchReviews,
    handleStoreChanged,
    selectedStore,
    stores,
    selectedStoreBranches,
    handleBranchesPick,
    selectedBranchesIds,
    handleRatingChange,
    selectedRating,
    reviews,
    handlePageChange,
    page,
    handleOrderDetailsOpen,
    handleOrderDetailsClose,
    orderDetails,
    isOrderDetailsOpen,
    isReviewsFetching,
    isOrderDetailsFetching,
    handleSelectImages,
    selectedImages,
    handleImagesClose,
    isImagesOpen,
    selectedStoreId,
    isSearchButtonDisabled,
  } = useUnit({
    handleSearchReviews: reviewsSearched,
    handleStoreChanged: storeIdChanged,
    selectedStore: $selectedStore,
    selectedStoreId: $selectedStoreId,
    stores: $filteredStores,
    selectedStoreBranches: $selectedStoreBranches,
    handleBranchesPick: branchesIdsChanged,
    selectedBranchesIds: $selectedBranchesIds,
    handleRatingChange: ratingChanged,
    selectedRating: $selectedRatingRange,
    reviews: $reviews,
    handlePageChange: pageChanged,
    page: $page,
    handleOrderDetailsOpen: orderDetailsOpened,
    handleOrderDetailsClose: orderDetailsClosed,
    orderDetails: $orderDetails,
    isOrderDetailsOpen: $isOrderDetailsOpen,
    isReviewsFetching: fetchReviewsFx.pending,
    isOrderDetailsFetching: fetchOrderFx.pending,
    handleSelectImages: imagesSelected,
    selectedImages: $selectedImages,
    handleImagesClose: imagesClosed,
    isImagesOpen: $isImagesOpen,
    isSearchButtonDisabled: $isSearchButtonDisabled,
  });
  const {t} = useTranslation();
  useGate(ratingGate);

  const RATING_FILTER_OPTIONS: RatingFilters = [
    {
      value: 'all',
      label: t('rating.all_reviews'),
    },
    {
      value: 'positive',
      label: t('rating.positive'),
    },
    {
      value: 'negative',
      label: t('rating.negative'),
    },
  ];

  const storePickView = useMemo(() => {
    if (!stores) return;
    if (stores.length < 2) return;

    return (
      <div className={styles.Select}>
        <Select
          mode="single"
          onSelect={handleStoreChanged}
          selected={selectedStoreId}
          label={t('choose_store')}
          options={stores}
        />
      </div>
    );
  }, [stores, selectedStoreId, i18n.language]);

  const branchesPickView = useMemo(() => {
    if (!selectedStoreBranches) return;

    return (
      <div className={styles.Select}>
        <Select
          mode="multiple"
          label={t('choose_branch')}
          options={selectedStoreBranches}
          onSelect={handleBranchesPick}
          selected={selectedBranchesIds}
        />
      </div>
    );
  }, [selectedStoreBranches, selectedBranchesIds, i18n.language]);

  const reviewsSkeletonView = useMemo(() => {
    if (!isReviewsFetching) return;

    const SKELETONS = Array.from({length: 5});

    return (
      <>
        <Space padding={10} />
        {SKELETONS.map((_, index) => (
          <div key={index}>
            <Space padding={6} />
            <Skeleton width="100%"
              height={110}
              radius={16}
            />
          </div>
        ))}
      </>
    );
  }, [isReviewsFetching]);

  const noReviewsView = useMemo(() => {
    if (!reviews) return;
    if (reviews.count > 0) return;

    return (
      <>
        <Space padding={50} />
        <EmptyState
          illustration="noReviews"
          title={t('rating.not_reviews')}
          description={t('rating.search_reviews_tip')}
        />
      </>
    );
  }, [reviews, i18n.language]);

  const reviewListView = useMemo(() => {
    if (!reviews) return;
    if (reviews.count < 1) return;
    if (!selectedStore) return;

    return (
      <div>
        <Space padding={8} />
        {reviews.list.map((review) => (
          <div key={review.orderId}
            className={styles.Review}
            onClick={() => handleOrderDetailsOpen(review.orderId)}
          >
            <div className={styles.ReviewInfo}>
              <Typography level="text"
                semibold
              >
                #{review.orderId}
              </Typography>
              <Typography level="sub-text"
                kind="secondary"
              >
                {review.branch.name} • {review.review.date}
              </Typography>
              <StarRating rating={review.review.rating} />

              {review.review.comment.length > 0 ? <Typography level="text">{review.review.comment}</Typography> : null}

              {review.review.images.length > 0 ? (
                <div className={styles.Images}>
                  {review.review.images.map((imageSrc, _, images) => (
                    <div
                      className={styles.Image}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelectImages(images);
                      }}
                      key={imageSrc.src}
                    >
                      <ImageCover coverUrl={imageSrc.src} />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className={styles.ReviewAction}>
              <Typography level="text"
                kind="secondary"
              >
                {t('rating.to_details')}
              </Typography>
              <Icon name="chevron-right"
                size={16}
              />
            </div>
          </div>
        ))}
        {reviews.list.length && (
          <div className={styles.Pagination}>
            <Pagination
              contentLength={reviews.count}
              onChange={({pageNumber}) => handlePageChange(pageNumber)}
              selected={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>
    );
  }, [reviews, page, selectedStore, isImagesOpen, i18n.language]);

  return (
    <div className={styles.Reviews}>
      <Typography level="h1">{t('sections.rating')}</Typography>

      <Space padding={24} />

      <div className={styles.Control}>
        <div className={styles.Filters}>
          {storePickView}
          {branchesPickView}
          <div className={styles.Select}>
            <Select
              mode="single"
              label={t('rating.review_type')}
              onSelect={handleRatingChange}
              selected={selectedRating}
              options={RATING_FILTER_OPTIONS}
            />
          </div>
          <Button
            kind="primary"
            size="large"
            shape="square"
            onClick={handleSearchReviews}
            disabled={isSearchButtonDisabled}
            loading={isReviewsFetching}
            icon={<Icon name="search" />}
          />
        </div>
      </div>

      {reviewListView}
      {reviewsSkeletonView}
      {noReviewsView}

      <Drawer width="488px"
        visible={isOrderDetailsOpen}
        onOutsideClick={handleOrderDetailsClose}
      >
        <OrderDetails
          orderDetails={orderDetails}
          isLoading={isOrderDetailsFetching}
          handleOrderDetailsClose={handleOrderDetailsClose}
        />
      </Drawer>
      {selectedImages && (
        <Lightbox open={isImagesOpen}
          close={handleImagesClose}
          slides={selectedImages}
          animation={{swipe: 2}}
        />
      )}
    </div>
  );
};
