import {useEffect} from 'react';
import * as model from '../model';
import {useUnit} from 'effector-react';
import {history} from '@shared/history';
import styles from './page.module.scss';
import {useParams} from 'react-router-dom';
import {useQuery} from '@shared/lib/use-query';
import {PageParams} from '../model';
import {OfferCard, OfferModal, QuantityControl} from './ui';
import {Icon, Input, Modal, Button, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';

export const ProductDetails = () => {
  const {
    handlePageOpen,
    handlePageClose,
    handlePriceChange,
    handleDeleteProductSubmit,
    handleProductDetailsSubmit,
    handleOfferDetailsDrawerOpen,
    handleDeleteProductDialogOpen,
    handleDeleteProductDialogClose,

    offers,
    product,

    isProductUpdatePending,
    isProductWithCombinations,
    isDeleteProductModalOpen,
  } = useUnit({
    handlePageOpen: model.pageOpened,
    handlePageClose: model.pageClosed,
    handlePriceChange: model.productPriceChanged,
    handleDeleteProductSubmit: model.deleteProductSubmitted,
    handleProductDetailsSubmit: model.productDetailsSubmitted,
    handleOfferDetailsDrawerOpen: model.offerDetailsDrawerOpened,
    handleDeleteProductDialogOpen: model.deleteProductModalOpened,
    handleDeleteProductDialogClose: model.deleteProductModalClosed,

    offers: model.$offers,
    product: model.$updatedProduct,

    isProductUpdatePending: model.$isProductUpdatePending,
    isDeleteProductModalOpen: model.$isDeleteProductModalOpen,
    isProductWithCombinations: model.$isProductWithCombinations,
  });
  const {t} = useTranslation();

  const query = useQuery();
  const {storeId, productId} = useParams<PageParams>();

  useEffect(() => {
    handlePageOpen({
      storeId: Number(storeId),
      productId: Number(productId),
      branchId: Number(query.get('branchId')),
    });

    return () => handlePageClose();
  }, [storeId, query, query]);

  if (!product) return null;

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.ContentWrapper}>
          <div onClick={history.goBack}
            className={styles.BackButton}
          >
            <Icon size={16}
              name="chevron-left"
            />
            <Typography level="text">{t('menu.to_products')}</Typography>
          </div>
          <Typography level="h1"
            className={styles.PageTitle}
          >
            {t('menu.product_details')}
          </Typography>
          <div className={styles.Content}>
            <div className={styles.Details}>
              <div>
                <Typography bold
                  level="h5"
                  className={styles.SectionTitle}
                >
                  {t('menu.about_product')}
                </Typography>
                <div className={styles.DetailsForm}>
                  <Input fluid
                    disabled
                    value={product.name}
                    label={t('product_name')}
                  />
                  {!isProductWithCombinations && (
                    <Input fluid
                      label={t('product_price')}
                      value={product.price.sold}
                      onChange={handlePriceChange}
                    />
                  )}
                </div>
              </div>
              <QuantityControl />
            </div>
            {isProductWithCombinations && (
              <div className={styles.Offers}>
                <Typography bold
                  level="h5"
                  className={styles.SectionTitle}
                >
                  {t('menu.variations')}
                </Typography>
                <div className={styles.OffersList}>
                  {offers &&
                    offers.map((offer) => (
                      <OfferCard offer={offer}
                        key={offer.productId}
                        onOfferEdit={handleOfferDetailsDrawerOpen}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
          {/*Заккоментировал кнопку удаления товара, так как работает некорекктно.*/}
          {/*<div className={styles.DeleteButton}*/}
          {/*  onClick={handleDeleteProductDialogOpen}*/}
          {/*>*/}
          {/*  <Icon name="trash"*/}
          {/*    size={20}*/}
          {/*  />*/}
          {/*  <Typography level="text">Удалить для всех филиалов</Typography>*/}
          {/*</div>*/}
        </div>
        <div className={styles.Footer}>
          <div className={styles.ButtonWrapper}>
            <Button fluid
              size="large"
              loading={isProductUpdatePending}
              onClick={handleProductDetailsSubmit}
            >
              {t('actions.save_changes')}
            </Button>
          </div>
        </div>
      </div>

      <OfferModal />

      <Modal
        visible={isDeleteProductModalOpen}
        onClose={handleDeleteProductDialogClose}
        onOutsideClick={handleDeleteProductDialogClose}
      >
        <div className={styles.DeleteModal}>
          <Typography semibold
            level="h4"
            className={styles.DeleteModalTitle}
          >
            {t('menu.question_delete_product')}
          </Typography>
          <Typography level="text"
            className={styles.DeleteModalDescription}
          >
            {t('actions.delete_cancel_impossible')}
          </Typography>
          <div className={styles.DeleteModalFooter}>
            <Button fluid
              size="large"
              kind="secondary"
              onClick={handleDeleteProductDialogClose}
            >
              {t('actions.cancel')}
            </Button>
            <Button fluid
              size="large"
              kind="danger"
              onClick={handleDeleteProductSubmit}
            >
              {t('delete_product')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
