import {$isAuthorized} from '@entities/session';
import {$user} from '@entities/user';
import {useUnit} from 'effector-react';
import React, {FC, useEffect} from 'react';
import {Redirect, Switch, useLocation} from 'react-router-dom';
import {Route} from 'react-router-dom';
import {AGREEMENT_ROUTE, LOGIN_ROUTE, PRIVATE_ROUTES} from '@shared/routing';
import '@express-24/theseus-ui/build/index.css';
import './app.css';
import './assets/fonts/inter/stylesheet.css';
import {Header} from '../widgets/header/ui';
import {hasAccess} from './model';
import {$isNotificationModalOpen, notificationModalClosed} from './model';
import {Modal, Portal, Space, Typography} from '@express-24/theseus-ui';
import * as Sentry from '@sentry/react';
import {$stores} from '@entities/attached-stores';
import {useTranslation} from 'react-i18next';

const PRODUCTION_URL = 'merchant.express24.uz';
const isProduction = window.location.hostname === PRODUCTION_URL;

//TODO: нужно всю логику пересмотреть
export const App: FC = () => {
  const {pathname} = useLocation();
  const {isAuthorized, user, isNotificationModalOpen, handleNotificationModalClose, stores} = useUnit({
    isAuthorized: $isAuthorized,
    user: $user,
    isNotificationModalOpen: $isNotificationModalOpen,
    handleNotificationModalClose: notificationModalClosed,
    stores: $stores,
  });
  const {t} = useTranslation();

  useEffect(() => {
    if (!isProduction) return;

    Sentry.init({
      dsn: 'https://ffa29a574c7f5bf622fe9dd309210767@o1243335.ingest.sentry.io/4506144367575040',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['https://api.express24.uz/'],
        }),
        new Sentry.Replay({
          maskAllInputs: false,
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: ['https://express24.uz'],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    if (!stores) return;
    if (!user) return;

    Sentry.setUser({username: `${stores[0].name} - ${user.name}`});
  }, []);

  if (pathname === AGREEMENT_ROUTE.path) {
    return (
      <Route path={AGREEMENT_ROUTE.path}
        component={AGREEMENT_ROUTE.component}
      />
    );
  }

  if (isAuthorized) {
    return (
      <>
        <Portal>
          <Header />
        </Portal>
        <Space padding={39} />
        <Switch>
          {user &&
            PRIVATE_ROUTES.map(({path, exact, component: Component, access}) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                render={() => {
                  return hasAccess(access, user.role.id) ? <Component /> : <Redirect to="/404" />;
                }}
              />
            ))}
        </Switch>
        <Modal visible={isNotificationModalOpen}
          onClose={handleNotificationModalClose}
        >
          <Space padding={24}>
            <Typography level="h5">{t('branch_disabled')}</Typography>
            <Space padding={6} />
            <Typography level="text">{t('branch_disabled_reason')}</Typography>
            <Space padding={8} />
            <Typography level="text">{t('branch_disabled_decision')}</Typography>
          </Space>
        </Modal>
      </>
    );
  }

  if (pathname === LOGIN_ROUTE.path) {
    return (
      <Route path={LOGIN_ROUTE.path}
        component={LOGIN_ROUTE.component}
      />
    );
  }

  return <Redirect to={LOGIN_ROUTE.path} />;
};
